<template>
  <b-container>
    <b-modal
      id="modal-center4"
      centered
      title="Serve Patient"
    >
      <b-button
        variant="primary"
        @click="goToAppointment()"
      >
        Serve Patient
      </b-button>
    </b-modal>
    <b-row
      style="width:100%"
      class="mx-auto mt-2"
    >
      <list-component
        check-action-text="Reschedule"
        :filter-inputs="filterInputs"
        style="width:100%"
        :doctype="doctype"
        :fields="fields"
        :is-appointment="false"
        @selected="rowSelected"
        @selected-action="multipleSelected"
        @rescheduleAction="rescheduleAction"
      />
    </b-row>
  </b-container>
</template>

<script>
import ListComponent from '@/views/components/doctype-list/ListComponent.vue'

export default {
  components: { ListComponent },
  data() {
    return {
      doctype: 'Shared Health Record',
      title: 'Patient Referrals',
      subTitle: '',
      fields: [
        'patient_name', { key: 'universal_patient_identifier', label: 'Unique Identifier' }, 'facility_name', 'status'],
      filterInputs: [],
      id: null,
    }
  },mounted(){
     this.setTitle("Referal Ins")
  },
  methods: {
    goToAppointment() {
      this.api({
        method: 'clinical.clinical.doctype.shared_health_record.shared_health_record.serve_client',
        args: { doc_reference: this.id },
      }).then(response => {
        if (response.message) {
          // Handle the response after referring the patient
        }
      })
    },
    rowSelected(item) {
      if (item && item.length) {
        this.id = item[0].name
        this.$bvModal.show('modal-center4')
      }
    },
  },
}
</script>

  <style></style>
